import { CategoriesData, ImageSizes, TvShow, TvSeriesData } from 'common/types/types-tvApi';
import config from 'config';

export function getSrcSet(imageSizes: ImageSizes) {
  const imageCandidates = Object.entries(imageSizes).reduce((prev, [size, imageUrl]) => {
    if (!imageUrl) return prev;
    const imageCandidate = `${imageUrl} ${size.replace(/w(\d*)/, '$1w')}`;
    return [...prev, imageCandidate];
  }, [] as string[]);

  return imageCandidates.join(', ');
}

export async function getCategoriesData(): Promise<CategoriesData> {
  const res = await fetch(`${config.tvApiUrl}/pages/genres?device=web`, {
    next: { revalidate: 10 },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch categories data: ${res.status}`);
  }

  return res.json();
}

export async function getSearchData(): Promise<TvSeriesData> {
  const res = await fetch(`${config.tvApiUrl}/pages/tv-series?device=web`, {
    cache: 'no-store',
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch categories data: ${res.status}`);
  }

  return res.json();
}

export async function getFeaturedShowsData(): Promise<TvShow[]> {
  const res = await fetch(`${config.tvApiUrl}/pages/home?device=web`, {
    next: { revalidate: 10 },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch featured shows data: ${res.status}`);
  }

  const data = await res.json();

  // featured is always the first element
  const [featuredShowsData] = data.items;

  return featuredShowsData.items;
}

export async function getCategoryData(category: string) {
  const res = await fetch(`${config.tvApiUrl}/pages/genres/${category}?device=web`, {
    next: { revalidate: 10 },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch featured shows data: ${res.status}`);
  }

  const data = await res.json();

  return data;
}
